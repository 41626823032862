import React from 'react';
import { Link } from 'react-router-dom';
import { Container, NavDropdown, Navbar } from 'react-bootstrap';

const Navigation = () => (
	<Navbar id="navigation" expand="md" variant="dark" bg="dark" fixed="top">
		<Container fluid>
			<Navbar.Brand href="/">Spartanbeta</Navbar.Brand>
			<Navbar.Toggle aria-controls="navbarScroll" />
			<Navbar.Collapse id="navbarScroll" className="flex-grow-0">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item">
						<Link to="/about" className="nav-link">
							About Me
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/skills" className="nav-link">
							Skills
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/projects" className="nav-link">
							Site Work
						</Link>
					</li>
					<li className="nav-item">
						<Link to="/contact" className="nav-link">
							Contact
						</Link>
					</li>
					<li className="nav-item">
						<NavDropdown title="Fun Stuff">
							<NavDropdown.Item>
								<Link to="/galaga" className="nav-link">
									Galaga
								</Link>
							</NavDropdown.Item>
						</NavDropdown>
					</li>
				</ul>
			</Navbar.Collapse>
		</Container>
	</Navbar>
	// </nav>
);

export default Navigation;
