import React from 'react';
import { Spinner } from 'react-bootstrap';
import '../styles/projectview.scss';
import { useState } from 'react';

const ProjectView = (props) => {
	const [isLoading, setIsLoading] = useState(true);

	function handleLoading() {
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	}

	return (
		<div className="projectview-container">
			<div className="wrapper">
				<div className="monitor">
					<div
						className="screen"
						style={isLoading ? { padding: '0px' } : { padding: '3.5%' }}
					>
						{isLoading && (
							<div className="loadingScreen">
								<div className="text-center loadingSpinner">
									<Spinner animation="border" role="status" />
									<h1>Loading...</h1>
								</div>
							</div>
						)}
						<iframe
							src={props.url}
							onLoad={handleLoading}
							style={isLoading ? { display: 'none' } : { display: 'block' }}
						></iframe>
					</div>
					<img
						className="macdesktop"
						src="/mac-desktop-frame.png"
						alt="Mac Desktop Computer"
					/>
				</div>
				<div className="siteLinks">
					<a href={props.url} target="_blank" title={`Visit ${props.sitename}`}>
						<img src="/magic-mouse.png" alt="Mac Mouse" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default ProjectView;
