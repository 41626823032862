import React from 'react';
import Layout from '../components/Layout';
import ProjectView from '../components/ProjectView';

const Projects = () => (
	<Layout>
		<div id="projects-container" className="pt-4">
			<ul>
				<li>
					<ProjectView
						url="https://zerodayresupply.com"
						sitename="Zero Day Resupply"
					/>
				</li>
				<li>
					<ProjectView
						url="https://twinrivernh.com"
						sitename="Twin River Campground and Cottages"
					/>
				</li>
				<li>
					<ProjectView
						url="https://sunshinestatelawoffice.com"
						sitename="Sunshine State Law Office"
					/>
				</li>
				<li>
					<ProjectView
						url="http://riverbendministorage.com"
						sitename="Riverbend Mini Storage"
					/>
				</li>
			</ul>
		</div>
	</Layout>
);

export default Projects;
