import { useEffect, useRef, useState } from 'react';
import Layout from '../components/Layout';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Galaga = () => {
	const shipRef = useRef(null);
	const screenRef = useRef(null);
	const [shipPosition, setShipPosition] = useState({ left: 0, bottom: 0 });
	const shipPositionRef = useRef(null);
	let screenHeight;
	const [bullets, setBullets] = useState([]);
	const [enemies, setEnemies] = useState([]);
	const [reloaded, setReloaded] = useState(true);
	const reloadedRef = useRef();
	const [direction, setDirection] = useState();
	const [score, setScore] = useState(0);
	const [pause, setPause] = useState(false);
	const [extraLives, setExtraLives] = useState(2);
	const [isInvincible, setIsInvincible] = useState(false);
	const [gameOver, setGameOver] = useState(false);
	const [stars, setStars] = useState([]);

	useEffect(() => {
		shipPositionRef.current = shipPosition;
	}, [shipPosition]);
	useEffect(() => {
		reloadedRef.current = reloaded;
	}, [reloaded]);

	//Set up controls
	useEffect(() => {
		document.addEventListener('keydown', (e) => {
			switch (e.code) {
				case 'ArrowLeft':
					setDirection('left');
					break;
				case 'ArrowRight':
					setDirection('right');
					break;
				case 'Space':
					fire();
					break;
				default:
					break;
			}
		});
		document.addEventListener('keyup', (e) => {
			switch (e.code) {
				case 'ArrowLeft':
					setDirection(null);
					break;
				case 'ArrowRight':
					setDirection(null);
					break;
				case 'Space':
					setReloaded(true);
					break;
				default:
					break;
			}
		});
		window.addEventListener('resize', () => {
			setupStars();
		});

		screenHeight = screenRef.current.getBoundingClientRect().height - 50;
		setupStars();
		resetShipPosition();
	}, []);

	//Game Loop
	useEffect(() => {
		const loop = setInterval(() => {
			if (!pause && !gameOver) {
				//Move the ship
				if (direction === 'left') {
					moveLeft();
				}
				if (direction === 'right') {
					moveRight();
				}

				//Remove bullets that have gone offscreen
				let tempBullets = [];
				bullets.forEach((bullet, i) => {
					if (bullet.y > 0) {
						tempBullets.push(bullet);
					}
				});

				//Check for hits on enemies
				let tempEnemies = [...enemies];
				tempBullets.forEach((bullet, bulletIndex) => {
					tempEnemies.forEach((enemy, enemyIndex) => {
						if (
							Math.abs(bullet.center - enemy.center) < 10 &&
							Math.abs(bullet.y - enemy.y) < 10
						) {
							tempBullets.splice(bulletIndex, 1);
							tempEnemies.splice(enemyIndex, 1);
							handleScore(10);
						}
					});
				});
				//Move the bullets
				setBullets(
					tempBullets.map((bullet) => {
						return new Bullet({
							x: bullet.x,
							y: bullet.y - 5,
						});
					})
				);
				// Create new enemies
				if (enemies.length < 50) {
					if (Math.random() < 0.02) {
						const newEnemy = new Enemy({
							x:
								Math.random() * screenRef.current.getBoundingClientRect().width,
							y: 0,
						});
						tempEnemies = [...enemies, newEnemy];
					}
				}
				//Move the enemies
				//Place enemies back at the top if they make it to the bottom
				setEnemies(
					tempEnemies.map((enemy) => {
						return new Enemy({
							x: enemy.x,
							y:
								enemy.y < screenRef.current.getBoundingClientRect().height
									? enemy.y + 3
									: 0,
							imageURL: enemy.imageURL,
						});
					})
				);
				//Check for Ship collision with enemy
				enemies.forEach((enemy) => {
					if (
						Math.abs(shipPosition.left + 25 - enemy.center) < 10 &&
						Math.abs(shipRef.current.offsetTop - enemy.y - 20) < 10
					) {
						handleDeath();
					}
				});
			}
		}, 20);

		return () => clearInterval(loop);
	}, [bullets, enemies]);

	const moveLeft = () => {
		setShipPosition((prevPosition) => {
			if (prevPosition.left > 0) {
				return {
					...prevPosition,
					left: prevPosition.left - 8,
				};
			} else {
				return prevPosition;
			}
		});
	};
	const moveRight = () => {
		setShipPosition((prevPosition) => {
			if (
				prevPosition.left + shipRef.current.getBoundingClientRect().width + 10 <
				screenRef.current.getBoundingClientRect().width
			) {
				return {
					...prevPosition,
					left: prevPosition.left + 8,
				};
			} else {
				return prevPosition;
			}
		});
	};
	const handleDeath = () => {
		if (!isInvincible) {
			//reduce number of lives
			setExtraLives((extraLives) => {
				if (extraLives > 0) {
					newLife();
					return extraLives - 1;
				} else {
					endGame();
					return extraLives;
				}
			});
			//display message to user
		}
	};
	const newLife = () => {
		//reset the ship position, make temporarily invincible for 3 seconds
		setIsInvincible(true);
		setTimeout(() => {
			setIsInvincible(false);
		}, [3000]);
	};
	const endGame = () => {
		setGameOver(true);
	};
	const resetGame = () => {
		setExtraLives(2);
		setEnemies([]);
		setBullets([]);
		setScore(0);
		resetShipPosition();
		setGameOver(false);
	};
	const handleScore = (points) => {
		setScore(score + points);
	};
	const fire = () => {
		if (reloadedRef.current) {
			const bullet = new Bullet({
				x: shipPositionRef.current.left + 15,
				y: screenHeight,
			});
			setBullets((prevBullets) => {
				if (prevBullets.length < 10) {
					return [...prevBullets, bullet];
				} else {
					return prevBullets;
				}
			});
			setReloaded(false);
		}
	};
	const resetShipPosition = () => {
		const width = screenRef.current.getBoundingClientRect().width;
		setShipPosition((prevPosition) => {
			return {
				...prevPosition,
				left: width / 2 - shipRef.current.getBoundingClientRect().width / 2,
			};
		});
	};
	const setupStars = () => {
		if (screenRef.current) {
			let stars = [];
			const width = screenRef.current.getBoundingClientRect().width;
			const height = screenRef.current.getBoundingClientRect().height;
			for (let i = 0; i < 200; i++) {
				let x = Math.random() * width + 1;
				let y = Math.random() * height + 1;
				let opacity = Math.random();
				stars.push({ x: x, y: y, opacity: opacity });
			}
			setStars(stars);
		}
	};
	return (
		<Layout>
			<Container id="galaga-container" fluid>
				<Row style={{ height: '100%' }}>
					<Col style={{ height: '100%' }}>
						<Card className="shadow-card" style={{ height: '100%' }}>
							<Card.Header className="d-flex justify-content-between align-items-center">
								<img
									id="banner"
									src="/images/galaga/galaga_banner.png"
									className="d-inline-block"
								/>
								<div id="score-board">
									<div className="text-center">Score:</div>
									<div id="score-board-display">{score}</div>
								</div>
							</Card.Header>
							<Card.Body>
								<div id="screen-wrapper">
									<div ref={screenRef} id="video-screen">
										{stars.map((star, i) => {
											return (
												<FontAwesomeIcon
													key={i}
													icon={faStar}
													color="white"
													style={{
														position: 'absolute',
														left: star.x + 'px',
														top: star.y + 'px',
														opacity: star.opacity,
														fontSize: '.4em',
													}}
												/>
											);
										})}
										{bullets.map((bullet, i) => bullet.render(i))}
										{enemies.map((enemy, i) => enemy.render(i))}
										<Ship
											shipRef={shipRef}
											shipPositionRef={shipPositionRef}
											isInvincible={isInvincible}
										/>
										{gameOver && (
											<>
												<h1 id="gameover">Game Over</h1>
												<Button
													id="reset-button"
													variant="danger"
													onClick={resetGame}
												>
													Restart
												</Button>
											</>
										)}
									</div>
								</div>
							</Card.Body>
							<div id="extra-lives">
								{extraLives > 1 && <img src="/images/galaga/galaga_ship.png" />}
								{extraLives > 0 && <img src="/images/galaga/galaga_ship.png" />}
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default Galaga;

const Ship = ({ shipRef, shipPositionRef, isInvincible }) => {
	const imageURL = '/images/galaga/galaga_ship.png';
	return (
		<img
			ref={shipRef}
			id="ship"
			src={imageURL}
			style={{
				position: 'absolute',
				left: shipPositionRef?.current?.left,
				bottom: 0,
			}}
			className={isInvincible ? 'invincible' : ''}
		/>
	);
};

class Bullet {
	constructor({ x, y }) {
		this.imageURL = '/images/galaga/bullet.png';
		this.x = x;
		this.y = y;
		this.width = 20;
		this.center = x + this.width / 2;
	}

	render(key) {
		return (
			<img
				key={key}
				className="bullet"
				src={this.imageURL}
				style={{
					position: 'absolute',
					left: this.x,
					top: this.y,
				}}
			/>
		);
	}
}

class Enemy {
	constructor({ x, y, imageURL = null }) {
		this.imageURL = imageURL
			? imageURL
			: `/images/galaga/enemy_${Math.floor(Math.random() * 4) + 1}.png`; //random image, pick of 4 enemies
		this.x = x;
		this.y = y;
		this.width = 30;
		this.center = x + this.width / 2;
	}

	render(key) {
		return (
			<div
				key={key}
				className="enemy"
				style={{
					position: 'absolute',
					left: this.x,
					top: this.y,
				}}
			>
				<img src={this.imageURL} />
			</div>
		);
	}
}
