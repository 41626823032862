import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import api from '../AxiosConfig';
import {
	Button,
	Form,
	FormControl,
	InputGroup,
	Row,
	Col,
	Card,
} from 'react-bootstrap';

const ContactForm = ({ setBackgroundLettering }) => {
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState('');
	const [messageSuccess, setMessageSuccess] = useState(false);
	const [tempEmail, setTempEmail] = useState();

	useEffect(() => {
		console.log(message);
		setBackgroundLettering(
			message || "Hello!\nHow's it going? I'd love to hear from you."
		);
	}, [message]);

	function handleSend(e) {
		e.preventDefault();
		let form = new FormData(e.target);
		let message = form.get('message');
		let email = form.get('email');
		let payload = {
			message: message,
			email: email,
		};

		api
			.post('/contact', payload)
			.then((res) => {
				console.log(res);
				if (res.status === 200) {
					setTempEmail(email);
					setMessageSuccess(true);
					//Clear fields
					setMessage('');
					setEmail('');
				}
			})
			.catch((err) => console.log(err));
	}

	const displayMessage = () => (
		<Col md={{ span: 6, offset: 3 }}>
			<Card className="shadow-card mt-4">
				<Card.Header>
					<h1 className="text-center text-shadow">Message sent.</h1>
				</Card.Header>
				<Card.Body>
					<div className="returnAddress text-center">
						I'll get right back to you{' '}
						<FontAwesomeIcon className="atSymbol" icon={faAt} /> {tempEmail}
					</div>
					<div className="text-center">
						<FontAwesomeIcon
							icon={faEnvelope}
							style={{ fontSize: '10rem', color: 'orange' }}
						/>
					</div>
				</Card.Body>
			</Card>
		</Col>
	);
	const displayForm = () => (
		<Row className="pt-4">
			<Col md={{ span: 6, offset: 3 }}>
				<Card className="shadow-card">
					<Card.Header>
						<Card.Title>
							<h1 className="text-center">Contact</h1>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<Form onSubmit={handleSend}>
							<Form.Group>
								<textarea
									required
									className="form-control"
									type="text"
									name="message"
									rows="5"
									onChange={(e) => {
										setMessage(e.target.value);
									}}
									value={message}
								></textarea>
							</Form.Group>
							<Form.Group className="mt-4">
								<InputGroup>
									<InputGroup.Text>
										<FontAwesomeIcon icon={faAt} />
									</InputGroup.Text>
									<FormControl
										required
										type="email"
										name="email"
										onChange={(e) => {
											setEmail(e.target.value);
										}}
										value={email}
										placeholder="Your Email Address"
									/>
								</InputGroup>
							</Form.Group>
							<Form.Group>
								<Button
									className="btn btn-primary form-control mt-4"
									type="submit"
								>
									Send
								</Button>
							</Form.Group>
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);

	return <div>{messageSuccess ? displayMessage() : displayForm()}</div>;
};

export default ContactForm;
