import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import '../styles/home.scss';
import {
	faHammer,
	faWrench,
	faScrewdriver,
	faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

const Home = (props) => {
	let homeContainer = useRef();
	let parallaxContainer = useRef();
	let firstImage = useRef();
	let firstImagePlaceholder = useRef();
	let secondImage = useRef();
	let secondImagePlaceholder = useRef();
	const explodingBlockRef = useRef();
	const buildingBlockRef = useRef();
	let exploded = false;
	let built = false;

	useEffect(() => {
		let page = document.getElementsByClassName('page')[0];

		page.addEventListener('scroll', (e) => {
			//image is taller than screen, freeze when bottom of image touches bottom of screen, otherwise use the top as the freeze point
			if (
				firstImage.current.getBoundingClientRect().height > window.innerHeight
					? firstImage.current.getBoundingClientRect().bottom <=
					  window.innerHeight
					: firstImage.current.getBoundingClientRect().top <= 100
			) {
				firstImagePlaceholder.current.style.height =
					firstImage.current.offsetHeight + 'px';
				firstImage.current.classList.add('fixed-image');
				firstImage.current.getBoundingClientRect().height > window.innerHeight
					? (firstImage.current.style.bottom = '0px')
					: (firstImage.current.style.top = '0px');
				firstImagePlaceholder.current.style.display = 'block';
			}

			//Scroll has reached bottom of second pic
			if (
				secondImage.current.getBoundingClientRect().height > window.innerHeight
					? secondImage.current.getBoundingClientRect().bottom <=
					  window.innerHeight
					: secondImage.current.getBoundingClientRect().top <= 100
			) {
				secondImagePlaceholder.current.style.height =
					secondImage.current.offsetHeight + 'px';
				secondImage.current.classList.add('fixed-image');
				secondImage.current.getBoundingClientRect().height > window.innerHeight
					? (secondImage.current.style.bottom = '0px')
					: (secondImage.current.style.top = '0px');
				secondImagePlaceholder.current.style.display = 'block';
			}

			if (
				buildingBlockRef.current.getBoundingClientRect().top >=
				window.innerHeight
			) {
				firstImage.current.classList.remove('fixed-image');
			}
			if (
				explodingBlockRef.current.getBoundingClientRect().top >=
				window.innerHeight
			) {
				secondImage.current.classList.remove('fixed-image');
			}

			if (
				explodingBlockRef.current.getBoundingClientRect().top <=
				window.innerHeight / 1.5
			) {
				if (!exploded) {
					exploded = true;
					explodeLetters(explodingBlockRef.current);
				}
			}
			if (!built) {
				if (
					buildingBlockRef.current.getBoundingClientRect().top <=
					window.innerHeight / 1.5
				) {
					built = true;
					buildLetters(buildingBlockRef.current);
				}
			}
		});
		//Blow up the letters on click
		explodingBlockRef.current.addEventListener('click', () => {
			explodeLetters(explodingBlockRef.current);
		});
	}, []);

	const explodeLetters = async (block) => {
		let letters = Array.from(block.children);
		let positions = [];
		//First pass grabs the letters positions
		letters.forEach((letter, i) => {
			let rect = letter.getBoundingClientRect();
			positions.push({ left: rect.left + 'px', top: rect.top + 'px' });
		});
		//Reposition the letters
		letters.forEach((letter, i) => {
			letter.style.top = positions[i].top;
			letter.style.left = positions[i].left;
			letter.classList.add('boom');
		});
		//Animate the letters
		letters.forEach((letter, i) => {
			//Get a random direction and animate

			const newspaperSpinning = [
				{ transform: 'rotate(0) scale(1)' },
				{ transform: 'rotate(360deg) scale(0)' },
			];

			//Make some negative vectors
			function coinFlip(num) {
				if (Math.floor(Math.random() * 10) % 2 === 0) {
					return num * -1;
				}
				return num;
			}
			let xFactor = coinFlip(Math.random() * 2 * window.innerWidth);
			let yFactor = coinFlip(Math.random() * 2 * window.innerHeight);

			const explode = [
				{ transform: `translate(${xFactor}px, ${yFactor}px) rotate(360deg)` },
			];
			const explodeTiming = {
				duration: 1000,
			};
			//Run the animation of exploding letters.
			let animation = letter.animate(explode, explodeTiming);
			//Return the letters to their normal position.
			animation.addEventListener('finish', () => {
				letter.style.position = 'static';
			});
		});
	};

	const buildLetters = (block) => {
		let letters = Array.from(block.children);

		let positions = [];
		//First pass grabs the letters positions
		letters.forEach((letter, i) => {
			let rect = letter.getBoundingClientRect();
			positions.push({ left: rect.left + 'px', top: rect.top + 'px' });
		});

		letters.forEach((letter, i) => {
			let windowEdge = window.innerWidth + letter.getBoundingClientRect().left;
			letter.style.left = windowEdge + 'px';
			letter.style.position = 'absolute';
			letter.style.visibility = 'visible';
			const build = [
				{
					transform: `translateX(${
						positions[i].left.replace('px', '') - windowEdge
					}px)`,
				},
			];
			const buildTiming = {
				duration: 200 + 100 * i,
			};
			let animation = letter.animate(build, buildTiming);
			//Return the letters to their normal position.
			animation.addEventListener('finish', () => {
				letter.style.position = 'static';
				if (Array.from(letter.classList).includes('tools')) {
					setTimeout(() => {
						letter.style.display = 'none';
					}, 25 * i);
				}
			});
		});
	};

	return (
		<Layout>
			<div ref={homeContainer} className="home-container">
				<div ref={parallaxContainer} className="parallax-container">
					<div ref={firstImage} className="imageContainer panAnimation5">
						<img
							src="/doom-landing-background.png"
							alt="Standing in front of Mt. Doom"
						/>
						<img
							className="fade"
							src="/code-clouds.png"
							alt="Code in the clouds"
						/>
						<img
							className="fog"
							src="/fog-layer.png"
							alt="Fog over the mountain"
						/>
					</div>
					<div ref={firstImagePlaceholder} className="image-placeholder"></div>
					<div
						ref={buildingBlockRef}
						id="building-block"
						className="statement-block ps-4"
					>
						<span className="letter">B</span>
						<span className="letter">u</span>
						<span className="letter">i</span>
						<span className="letter">l</span>
						<span className="letter">d</span>
						<span className="letter">&nbsp;</span>
						<span className="letter">i</span>
						<span className="letter">t</span>
						<span className="letter">.</span>
						<FontAwesomeIcon className="tools" color="orange" icon={faHammer} />
						<FontAwesomeIcon className="tools" color="orange" icon={faWrench} />
						<FontAwesomeIcon
							className="tools"
							color="orange"
							icon={faScrewdriver}
						/>
						<FontAwesomeIcon
							className="tools"
							color="orange"
							icon={faToolbox}
						/>
					</div>
					<div ref={secondImage} className="imageContainer panAnimation7">
						<img
							// className="camino-at-anchor"
							src="/camino_at_anchor.jpg"
							alt="Camino at anchor"
						/>
					</div>
					<div ref={secondImagePlaceholder} className="image-placeholder"></div>
					<div
						ref={explodingBlockRef}
						id="exploding-block"
						className="statement-block justify-content-center"
						style={{ cursor: 'pointer' }}
					>
						<span className="letter">B</span>
						<span className="letter">r</span>
						<span className="letter">e</span>
						<span className="letter">a</span>
						<span className="letter">k</span>
						<span className="letter">&nbsp;</span>
						<span className="letter">i</span>
						<span className="letter">t</span>
						<span className="letter">.</span>
					</div>
					<div className="imageContainer panAnimation10">
						<img
							className="johnson-cay-panorama"
							src="/images/johnson_cay_panorama.jpg"
							alt="Camino anchored in Johnson Cay"
						/>
					</div>
					<div className="statement-block justify-content-end pe-4">
						Try until you make it!
					</div>
				</div>
			</div>
			{/* <Card className="quote-box">
							<Card.Body>
								<p>
									"Life is an adventure, one filled with risk and uncertainty.
									Challenges are laid in our path by circumstance or by choice,
									either way the only path is through."
								</p>
							</Card.Body>
						</Card> */}
		</Layout>
	);
};

export default Home;
