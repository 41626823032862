import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = (props) => (
	<div>
		<Navbar />
		<div ref={props.layoutRef} id="layout" className="page">
			{props.children}
		</div>
		{/* <Footer /> */}
	</div>
);

export default Layout;
