import { Container, Row, Col, Card } from 'react-bootstrap';
import Layout from '../components/Layout';

const About = () => {
	return (
		<Layout>
			<Container id="about-container">
				<Row>
					<Col sm={{ span: '6', offset: '3' }}>
						<Card className="shadow-card">
							<Card.Img
								variant="start"
								src="/images/family_bahamas.jpg"
							></Card.Img>
							<Card.Body>
								<Card.Title>Hi! My name is Chris. </Card.Title>
								<p>
									I am a father of two, husband to an amazing woman, adventurer
									and an enthusiastic developer with a wide range of technical
									skills. When I am not working on a coding project, I am
									typically found hiking <span>&#127956;</span>, sailing with my
									family <span>&#9973;</span> or planning our next endeavour.
								</p>
								<p>
									I started out on a traditional path. I worked hard through
									grade school, graduating class valedictorian. I attended
									college, graduated with a Computer Science Degree{' '}
									<span>&#128187;</span> at the top of my class... then I got
									distracted by this thing called the World!{' '}
									<span>&#127758;</span>
								</p>
								<p>
									It took a few years to get back on course professionally and
									there are some gaps in my employment history{' '}
									<span>&#128527;</span>, but I have a world of life experience
									and unmatched drive for completing difficult tasks.
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img
								variant="start"
								src="/images/sailing_from_panama.jpg"
							></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>San Blas, Panama</Card.Title>
									<Card.Subtitle className="text-end">(2022)</Card.Subtitle>
								</div>
								<Card.Text>
									Purchased a boat and sailed 1900 miles back to Florida.
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img
								variant="start"
								src="/images/camino_finish.jpg"
							></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>Camino de Santiago</Card.Title>
									<Card.Subtitle className="text-end">(2019)</Card.Subtitle>
								</div>
								<Card.Text>
									Hiked 800 miles across Spain carrying my son on my back.
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img
								variant="start"
								src="/images/cape_reinga.jpg"
							></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>Te Aaroa</Card.Title>
									<Card.Subtitle className="text-end">(2017)</Card.Subtitle>
								</div>
								<Card.Text>
									Walked 2000 miles across New Zealand for our honeymoon.
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img variant="start" src="/images/cdt_finish.png"></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>Continental Divide Trail</Card.Title>
									<Card.Subtitle className="text-end">(2012)</Card.Subtitle>
								</div>
								<Card.Text>Hiked 2900 miles from Mexico to Canada.</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img variant="start" src="/images/pct_finish.png"></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>Pacific Crest Trail</Card.Title>
									<Card.Subtitle className="text-end">(2010)</Card.Subtitle>
								</div>
								<Card.Text>Hiked 2650 miles from Mexico to Canada.</Card.Text>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} sm={6} md={4}>
						<Card className="shadow-card">
							<Card.Img variant="start" src="/images/at_finish.png"></Card.Img>
							<Card.Body>
								<div className="d-flex justify-content-between align-items-center">
									<Card.Title>Appalachian Trail</Card.Title>
									<Card.Subtitle className="text-end">(2007)</Card.Subtitle>
								</div>
								<Card.Text>
									Hiked 2200 miles over 6 months from Georgia to Maine.
								</Card.Text>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col xs={12} style={{ marginBottom: '2rem' }}>
						<Card className="shadow-card">
							<Card.Body>
								<p>
									Embarking on epic journeys like the Appalachian Trail, Pacific
									Crest Trail and Continental Divide Trail, has been an
									incredible testament to my adventurous spirit and resilience.
									Navigating these iconic trails has not only honed my physical
									capabilities but also deepened my connection with nature.
								</p>
								<p>
									My proficiency in coding and technology adds another dimension
									to my diverse skill set.
								</p>
								<p>
									The fusion of my outdoor achievements and technical expertise
									positioned me perfectly for starting and operating a unique
									startup company bringing an e-commerce service to the long
									distance hiking world.
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};
export default About;
