import axios from 'axios';

const Axios = axios.create({
	baseURL:
		process.env.NODE_ENV === 'development'
			? 'http://localhost:3001/api'
			: 'https://portfolio-k5t4.onrender.com/api',
});

export default Axios;
