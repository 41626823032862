import React, { useEffect, useState, useRef } from 'react';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faFile,
	faChevronUp,
	faBullseye,
	faCertificate,
	faBurst,
} from '@fortawesome/free-solid-svg-icons';
import { Card, Row, Col } from 'react-bootstrap';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

const Skills = () => {
	//State values
	const [allSkills, setAllSkills] = useState([
		{
			id: 'html5',
			name: 'HTML5',
			description:
				'Writing clean semantic HTML is the goal in each project I work on.',
		},
		{
			id: 'node',
			name: 'Node.js',
			description:
				'Asynchronous programming on the backend with Javascript was an answered prayer. In my beginning entrepreneurial days, synchronous PHP without additional frameworks was what I worked with.',
		},
		{
			id: 'css',
			name: 'CSS3',
			description:
				"Visual appeal is a big part of frontend development. Creating engaging UI's demands continued exploration in this department.",
		},
		{
			id: 'react',
			name: 'React',
			description:
				'My current playground for creative and modular interfaces. Building with React is reminiscent of my childhood days of Lego, snapping together building blocks to make something cool!',
		},
		{
			id: 'javascript',
			name: 'Javascript',
			description:
				'Ever a part of my development journey, Javascript has lent interactivity, then AJAX partial page loading, finally backend scripting with Node. My favorite language to work with and the most useful IMHO.',
		},
		{
			id: 'mongodb',
			name: 'MongoDB',
			description:
				'When I did a rewrite of my e-commerce startup I transitioned to a MERN stack and incorporated MongoDB on the backend.',
		},
		{
			id: 'mysql',
			name: 'MySQL',
			description:
				'Having created a business from scratch, I have a good deal of experience writing SQL queries to manage the many user interactions and product requirements of an e-commerce site.',
		},
		{
			id: 'php',
			name: 'PHP',
			description:
				'My original choice in backend scripting, I have used PHP on many projects beginning with my personal startup business where I wrote services to interface with USPS and UPS shipping rates and timetables, accept payments with PayPal and connect to my database.',
		},
		{
			id: 'photoshop',
			name: 'Photoshop',
			description:
				'Through the years I have utilized Photoshop for digitizing products for sale and creating engaging graphics for frontend projects.',
		},
		{
			id: 'wordpress',
			name: 'Wordpress',
			description:
				'When a client requires simple setup and management of their own site, Wordpress is often the tool of choice.',
		},
		{
			id: 'codeigniter',
			name: 'CodeIgniter',
			description:
				'CodeIgniter was my very first MVC framework. I used this to do a complete rewrite of my startup e-commerce site after the initial year of operation.',
		},
		{
			id: 'git',
			name: 'Git',
			description:
				'I use Git with GitHub for my personal project versioning, easily publishing my work and updating live microservices on Render.',
		},
		{
			id: 'svn',
			name: 'SVN',
			description:
				'At my last job, SVN was the chosen versioning used by the team for our continual development releases.',
		},
		{
			id: 'bootstrap',
			name: 'Bootstrap',
			description:
				'In my last Software Developer role I was charged with converting the entire legacy code base to Bootstrap 5. I have also used this library for a number of customer sites and the site you are viewing right now.',
		},
		{
			id: 'ionic',
			name: 'Ionic',
			description:
				'Ionic was the chosen frontend framework for the PWA we were developing in my last Software Developer role. We blended a mix of Material Design and IOS components to create our unique and consistent UI.',
		},
		{
			id: 'express',
			name: 'Express',
			description:
				'When setting up a backend service with Node, I enjoy the ease in which middleware can be applied using Express Server.',
		},
		{
			id: 'mongoose',
			name: 'Mongoose',
			description:
				"Well defined schema and data modeling, I have used Mongoose as part of a MERN stack e-commerce site managing user details and pulling from a customer's managed Square Database and caching results locally in MongoDB.",
		},
		{
			id: 'jquery',
			name: 'jQuery',
			description:
				'Adding interactivity to my personal startup e-commerce site, I have extensively used this library for dynamic Ajax loading of content with selective targeting.',
		},
	]);
	const [capturedSkills, setCapturedSkills] = useState([]);
	const [showSkills, setShowSkills] = useState({});
	const [numberTargets, setNumberTargets] = useState();

	//Refs
	const layoutRef = useRef(null);
	const crosshairsRef = useRef(null);
	const crosshairsImageRef = useRef(null);
	const skillsRef = useRef([]);
	const scoreBoardRef = useRef(null);
	const capturedSkillsRef = useRef(null);

	const hideCrosshairsRef = useRef(true);
	const [hideCrosshairs, setHideCrosshairs] = useState(true);
	useEffect(() => {
		hideCrosshairsRef.current = hideCrosshairs;
	}, [hideCrosshairs]);

	let firstMove = true;

	//Check for the number of skills captured
	useEffect(() => {
		//Remove crosshairs if all are captured
		if (capturedSkills.length === allSkills.length) {
			setHideCrosshairs(true);
		}
		setNumberTargets(allSkills.length - capturedSkills.length);
	}, [capturedSkills]);

	let mouseX, mouseY;
	let scrollX = 0;
	let scrollY = 0;

	useEffect(() => {
		//Setup the crosshairs
		const NAVBAR = crosshairsRef.current.getBoundingClientRect().top; //This is the offset of the NAVBAR which pushes the layout component down

		//Set initial position of the mouse

		//Update the crosshairs position when the screen is scrolled
		layoutRef.current.addEventListener('scroll', (e) => {
			//update global values to be used in mousemove
			scrollX = layoutRef.current.scrollLeft;
			scrollY = layoutRef.current.scrollTop;

			let x = mouseX + scrollX - crosshairsRef.current.offsetWidth / 2;
			let y =
				mouseY + scrollY - crosshairsRef.current.offsetHeight / 2 - NAVBAR;

			crosshairsRef.current.style.left = x + 'px';
			crosshairsRef.current.style.top = y + 'px';
		});

		//Update the crosshairs position when the mouse moves
		document.addEventListener('mousemove', function (e) {
			if (firstMove) {
				setHideCrosshairs(false);
				firstMove = false;
			}
			if (!hideCrosshairsRef.current && crosshairsRef.current) {
				//update global values to be used in scroll
				mouseX = e.clientX;
				mouseY = e.clientY;

				let x = e.clientX + scrollX - crosshairsRef.current.offsetWidth / 2;
				let y =
					e.clientY + scrollY - crosshairsRef.current.offsetHeight / 2 - NAVBAR;

				crosshairsRef.current.style.left = x + 'px';
				crosshairsRef.current.style.top = y + 'px';
			}
		});

		const skills = document.querySelectorAll('.skill');
		skillsRef.current = Array.from(skills);
		skillsRef.current.forEach((skill, i) => {
			let x =
				window.innerWidth * Math.random() - skill.getBoundingClientRect().width; // Initial horizontal position
			if (x <= 0) {
				x = 1;
			}
			let y =
				window.innerHeight * Math.random() -
				skill.getBoundingClientRect().height; // Initial vertical position
			if (y <= 0) {
				y = 1;
			}
			let xDirection = 1 * (i + 1); // Initial horizontal direction (1 for right, -1 for left)
			let yDirection = 1; // Initial vertical direction (1 for down, -1 for up)
			//Set a random speed for the skill image
			let speedX = Math.floor(Math.random() * 2) / 3;
			let speedY = Math.floor(Math.random() * 2) / 3;
			let prevSpeedX, prevSpeedY;
			if (speedX === 0) {
				speedX = 0.5;
			}
			if (speedY === 0) {
				speedY = 0.5;
			}

			//Fires shot when mouseover
			skill.addEventListener('mouseover', () => {
				if (!hideCrosshairsRef.current) {
					prevSpeedX = speedX;
					prevSpeedY = speedY;
					speedX = speedY = 0;

					crosshairsImageRef.current.src = '/images/crosshairs_yellow.png';
					//Make the score board blink
					scoreBoardRef.current.classList.add('blink');
					capturedSkillsRef?.current?.classList.add('blink');
					setTimeout(() => {
						setCapturedSkills((prevCapturedSkills) => {
							if (
								!prevCapturedSkills.includes(
									allSkills.find((s) => s.id === skill.id)
								)
							) {
								return [
									...prevCapturedSkills,
									allSkills.find((s) => s.id === skill.id),
								];
							} else {
								return prevCapturedSkills;
							}
						}); //add to captured skills
						skill.style.display = 'none';
						crosshairsImageRef.current.src = '/images/crosshairs.png';
						scoreBoardRef.current.classList.remove('blink');
						capturedSkillsRef?.current?.classList.remove('blink');
					}, 500);
				}
			});

			function updatePosition() {
				const boundingBox = skill.getBoundingClientRect();
				const windowWidth = window.innerWidth;
				const windowHeight = window.innerHeight;

				// Check if the image hits the right or left edge
				if (x + boundingBox.width >= windowWidth || x < 0) {
					xDirection *= -1; // Change horizontal direction
				}

				// Check if the image hits the bottom or top edge
				if (y + boundingBox.height >= windowHeight || y < 0) {
					yDirection *= -1; // Change vertical direction
				}

				// Update the position based on the direction
				x += xDirection * speedX; // Adjust the horizontal speed
				y += yDirection * speedY; // Adjust the vertical speed

				// Apply the new position to the image
				skill.style.left = `${x}px`;
				skill.style.top = `${y}px`;
			}

			function animate() {
				updatePosition();
				requestAnimationFrame(animate);
			}

			animate();
		});

		//Handle touch move for mobile
		document.addEventListener('touchstart', (e) => {
			setHideCrosshairs(false);
		});
		document.addEventListener('touchmove', (e) => {
			if (!hideCrosshairsRef.current) {
				//update global values to be used in scroll
				mouseX = e.touches[0].clientX;
				mouseY = e.touches[0].clientY;

				console.log('mouse', mouseX, mouseY);

				let x =
					e.touches[0].clientX +
					scrollX -
					crosshairsRef.current.offsetWidth / 2;
				let y =
					e.touches[0].clientY +
					scrollY -
					crosshairsRef.current.offsetHeight / 2 -
					NAVBAR;

				crosshairsRef.current.style.left = x + 'px';
				crosshairsRef.current.style.top = y + 'px';

				skillsRef.current.forEach((skill) => {
					if (
						skill.getBoundingClientRect().left <= mouseX &&
						mouseX <= skill.getBoundingClientRect().right &&
						skill.getBoundingClientRect().top <= mouseY &&
						mouseY <= skill.getBoundingClientRect().bottom
					) {
						skill.classList.add('touchmove');
						crosshairsImageRef.current.src = '/images/crosshairs_yellow.png';
						//Make the score board blink
						scoreBoardRef.current.classList.add('blink');
						capturedSkillsRef?.current?.classList.add('blink');
						setTimeout(() => {
							setCapturedSkills((prevCapturedSkills) => {
								if (
									!prevCapturedSkills.includes(
										allSkills.find((s) => s.id === skill.id)
									)
								) {
									return [
										...prevCapturedSkills,
										allSkills.find((s) => s.id === skill.id),
									];
								} else {
									return prevCapturedSkills;
								}
							}); //add to captured skills
							skill.style.display = 'none';
							crosshairsImageRef.current.src = '/images/crosshairs.png';
							scoreBoardRef.current.classList.remove('blink');
							capturedSkillsRef?.current?.classList.remove('blink');
							skill.classList.remove('touchmove');
						}, 500);
					}
				});
			}
		});
		document.addEventListener('touchend', (e) => {
			setHideCrosshairs(true);
		});
	}, []);

	const handleDescription = (skill) => {
		//find the skill and flip it's display value
		setShowSkills({ ...showSkills, [skill.id]: !showSkills[skill.id] });
	};

	return (
		<Layout layoutRef={layoutRef}>
			<div id="skills-container">
				<Row>
					<Col sm={{ span: 12 }} xl={{ span: 6, offset: 3 }}>
						<Card ref={scoreBoardRef} id="score-board" className="shadow-card">
							{numberTargets > 0 ? (
								<Card.Body>
									{numberTargets}{' '}
									<FontAwesomeIcon icon={faBullseye} color="red" />
									's remain.
								</Card.Body>
							) : (
								<Card.Body>
									<div>
										<FontAwesomeIcon
											icon={faCertificate}
											color="gold"
											size="xl"
										/>{' '}
										Resume Unlocked!
									</div>
									<div className="resume text-center mt-4">
										<a href="/Chris_Solinsky_Resume.pdf" target="_blank">
											<FontAwesomeIcon
												icon={faFilePdf}
												size="2xl"
												className="pdfFileIcon"
											></FontAwesomeIcon>
										</a>
										{/* <div className="ms-2">Resume</div> */}
									</div>
								</Card.Body>
							)}
						</Card>
						<Card id="skills-card" className="shadow-card">
							<Card.Body>
								<div className="avatar">
									<img src="/profile-avatar.png" alt="Profile Picture" />
								</div>
								<div className="description">
									{/* <p>
								"Life is an adventure, one filled with risk and uncertainty.
								Challenges are laid in our path by circumstance or by choice,
								either way the only path is through."
							</p> */}
									<p>
										Set your{' '}
										<span
											className="crosshairs-background"
											style={{ position: 'relative' }}
										>
											<img
												src="/images/crosshairs.png"
												style={{
													position: 'absolute',
													top: '0px',
													left: '0px',
													marginLeft: '50%',
													marginTop: '25%',
													transform: 'translate(-50%, -50%)',
													width: '100px',
												}}
											/>
											<strong style={{ position: 'relative', zIndex: '100' }}>
												sights
											</strong>
										</span>{' '}
										on what your goals.
									</p>
									<p className="d-inline-block d-md-none">Tap to fire!</p>
									<p className="d-none d-md-inline-block text-center">
										Click to fire!
									</p>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</Row>
				{capturedSkills.length > 0 && (
					<Card
						ref={capturedSkillsRef}
						id="captured-skills"
						className="shadow-card"
					>
						<Card.Header>
							<Card.Title className="text-center">Captured Skills</Card.Title>
						</Card.Header>
						<Card.Body>
							<div>
								{capturedSkills.map((skill, i) => {
									return (
										<Card key={i} className="mt-1">
											<Card.Body>
												<div className="captured-skill">
													<img src={`/images/${skill.id}.png`} />
													<div className="name">{skill.name}</div>
													<FontAwesomeIcon
														className="me-2"
														icon={
															showSkills[skill.id] ? faChevronUp : faChevronDown
														}
														onClick={() => handleDescription(skill)}
														style={{ cursor: 'pointer' }}
													/>
												</div>
												{showSkills[skill.id] && (
													<div className="captured-skill-description">
														{skill.description}
													</div>
												)}
											</Card.Body>
										</Card>
									);
								})}
							</div>
						</Card.Body>
					</Card>
				)}

				{/* These are the floating images */}
				<div className="skills">
					{allSkills.map((skill, i) => {
						return (
							<div key={i} className="skill bouncing-div" id={skill.id}>
								<img src={`/images/${skill.id}.png`} />
								<FontAwesomeIcon
									className="burst"
									icon={faBurst}
									size="10xl"
									style={{
										transform: `rotate(${Math.floor(Math.random() * 360)}deg)`,
									}}
								/>
							</div>
						);
					})}
				</div>

				<div
					ref={crosshairsRef}
					id="crosshairs"
					style={
						hideCrosshairs
							? { visibility: 'hidden' }
							: { visibility: 'visible' }
					}
				>
					<img ref={crosshairsImageRef} src="/images/crosshairs.png" />
				</div>
				{numberTargets === 0 && (
					<div id="joke-box">
						<img src="/images/particular_set_of_skills.png" />
					</div>
				)}
			</div>
		</Layout>
	);
};

export default Skills;
