import { useState } from 'react';
import { Container } from 'react-bootstrap';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const Contact = () => {
	const [backgroundLettering, setBackgroundLettering] = useState();

	return (
		<Layout>
			<Container className="contact-container">
				<ContactForm
					setBackgroundLettering={setBackgroundLettering}
				></ContactForm>
				<div className="background-lettering">{backgroundLettering}</div>
				<div className="background-lettering2">{backgroundLettering}</div>
			</Container>
		</Layout>
	);
};

export default Contact;
